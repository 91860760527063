<template>
  <div class="currencies-list" :class="{ 'is-show': props.isOpen }">
    <div class="items">
      <div v-if="filteredItems.length">
        <div
          v-for="item in filteredItems"
          :key="item.nativeCurrency"
          class="item"
          @click="selectCurrency(item.nativeCurrency)"
        >
          <div class="item__left">
            <atomic-image
              class="img"
              :src="`${iconUrl}/${item.nativeCurrency}.svg`"
              :defaultImage="DEFAULT_IMG"
            />

            <span class="amount">{{ item.currencySymbol }} {{ item.amount }}</span>
            <span class="code-title">{{ item.currency }}</span>
          </div>
          <atomic-icon id="checked" v-if="activeAccount && activeAccount.currency === item.nativeCurrency" />
        </div>
      </div>
      <div v-else class="item-empty">No result</div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';
  import { DEFAULT_IMG } from '@skeleton/consts/staticPath'
  import type { IAccount } from '@skeleton/core/types';

  const props = defineProps({
    isOpen: {
      type: Boolean,
      default: false,
    },
    payMethod: { type: String, default: undefined },
    payDefinition: { type: String, default: undefined },
  });

  const walletStore = useWalletStore();
  const globalStore = useGlobalStore();
  const { accounts, activeAccount, showEquivalentBalance } = storeToRefs(walletStore);
  const { iconUrl } = useDynamicIcon('currency');

  const { currencies } = storeToRefs(globalStore);

  const { switchAccount } = useWalletStore();
  const { createAccount } = useWalletStore();
  const { formatBalance, sortByAlphabet, getEquivalentAccount, getContent } = useProjectMethods();

  const { allSupportedCurrencies } = useSupportedCurrencies(props.payDefinition, props.payMethod);
  const emit = defineEmits(['close', 'changeActiveAccount']);

  const searchValue = ref<string>('');

  const getAccountByCurrency = (currency: string): Maybe<IAccount> =>
    accounts.value.find((account) => account.currency === currency);

  interface IDisplayAccount {
    nativeCurrency: string;
    currency: string;
    amount: number;
    currencySymbol?: string;
  }

  const formatCurrenciesList = (list: IDisplayAccount[]) => {
    return list.filter((item) => accounts.value.find((account) => account.currency === item.nativeCurrency));
  };

  const selectedItems = computed(() => {
    let supportedCurrencies = currencies.value.filter((currency) =>
      allSupportedCurrencies.value.some(
        (supportedCurrency) => supportedCurrency.toLocaleLowerCase() === currency.code.toLocaleLowerCase()
      )
    );

    if (!supportedCurrencies.length) supportedCurrencies = currencies.value;
    const formatList: IDisplayAccount[] = supportedCurrencies.map((currency) => {
      const findAccount = getAccountByCurrency(currency.code);

      if (showEquivalentBalance.value) {
        const equivalentAccount = getEquivalentAccount(
          findAccount?.balance || 0,
          findAccount?.currency || currency.code
        );
        return {
          nativeCurrency: currency.code,
          amount: equivalentAccount.balance,
          currency: currency.code,
          currencySymbol: equivalentAccount.currencySymbol,
        };
      }

      const formattedAcc = formatBalance(findAccount?.currency || currency.code, findAccount?.balance || 0);
      return { nativeCurrency: currency.code, ...formattedAcc };
    });

    const withBalanceList: IDisplayAccount[] = [];
    const withoutBalanceList: IDisplayAccount[] = [];

    formatList.forEach((formatItem) => {
      if (formatItem.amount) {
        withBalanceList.push(formatItem);
      } else {
        withoutBalanceList.push(formatItem);
      }
    });

    const withBalanceSortedList = formatCurrenciesList(withBalanceList);
    const withoutBalanceSortedList = formatCurrenciesList(withoutBalanceList);

    return [...withBalanceSortedList, ...withoutBalanceSortedList];
  });

  const filteredItems = computed(() => {
    const searchQuery = searchValue.value.toLowerCase().trim();

    if (!searchQuery) {
      return selectedItems.value;
    }
    return selectedItems.value.filter((item) => {
      return (
        item.currency.toLowerCase().includes(searchQuery) ||
        item.amount.toString().includes(searchQuery) ||
        (item.currencySymbol && item.currencySymbol.toLowerCase().includes(searchQuery))
      );
    });
  });

  const selectCurrency = async (currency: string): Promise<void> => {
    if (activeAccount.value?.currency === currency) return;
    const findAccount = getAccountByCurrency(currency);

    emit('close');

    if (findAccount) {
      await switchAccount(findAccount.id);
    } else {
      await createAccount(currency);
      const findNewAccount = getAccountByCurrency(currency);
      if (findNewAccount) {
        await switchAccount(findNewAccount.id);
      }
    }

    emit('changeActiveAccount');
  };
</script>

<style src="~/assets/styles/components/modal/currencies.scss" lang="scss" />
