import { useApiAuthInstance } from '@skeleton/core/assets/apiAuthInstance';

export function useSupportedCurrencies(initialPayDefinition?: string, initialPayMethod?: string) {
  const payDefinition = ref(initialPayDefinition);
  const payMethod = ref(initialPayMethod);
  const allSupportedCurrencies = ref<string[]>([]);

  const fetchSupportedCurrencies = async () => {
    if (!payDefinition.value || !payMethod.value) return;
    try {
      const { data } = await useApiAuthInstance(`/api/payment/currencies/${payDefinition.value}/${payMethod.value}`);
      allSupportedCurrencies.value = data;
    } catch (e) {
      allSupportedCurrencies.value = [];
    }
  };

  watch(
    [payDefinition, payMethod],
    async () => {
      await fetchSupportedCurrencies();
    },
    { immediate: true }
  );
  return { allSupportedCurrencies };
}
